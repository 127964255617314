import * as React from "react";
import {DraggableSyntheticListeners, useDraggable} from "@dnd-kit/core";
import {DragIndicator} from "@mui/icons-material";
import {FormatDuration} from "../../../../Formatting";
import {AsperaStatusIcon, DeleteStopRemoveIconButton} from "../../../Actions/AsperaStatusButtonIcon";
import {AudioResourceDefinitionIcon} from "../../../../StatusIcons/TrackStatusIcon";
import {Resource} from "../../../../api";

export interface Props {
    audioResource: Resource;
    isOverlay?: boolean;
    isMapped?: boolean;
    projectId: string;
}

export interface DraggableProps {
    children?: React.ReactNode;
    listeners?: DraggableSyntheticListeners;
}

export const DraggableAudioResource = (props: Props & DraggableProps & React.HTMLAttributes<HTMLTableRowElement>) => {

    const {attributes, listeners, setNodeRef} = useDraggable({id: props.audioResource.id!});
    return (
        <>
            <tr key={props.audioResource.id}
                aria-label={props.isOverlay ? `dragging ${ props.audioResource.filename}` : `resource ${ props.audioResource.filename}`}
                className={props.className} ref={setNodeRef} {...listeners} {...attributes}>
                <td className={"iconic"}>
                    <DragIndicator key="dragIndicator" />
                </td>

                <td key={"filename"} className={"word-break"}>   {props.audioResource.filename} </td>
                <td key={"audioInfo"} className={"numeric"}>     {props.audioResource.audioInfo?.sample_rate} </td>
                <td key={"bit_depth"} className={"numeric"}>     {props.audioResource.audioInfo?.bit_depth} </td>
                <td key={"channels"} className={"numeric"}>      {props.audioResource.audioInfo?.channels} </td>
                <td key={"duration"} className={"numeric"}>  {FormatDuration(props.audioResource.audioInfo?.duration ?? 0)} </td>

                <td className={"iconic"} >
                    <AsperaStatusIcon projectId={props.projectId} resource={props.audioResource} />
                </td>
                <td className={"iconic"} width={10}>
                    <AudioResourceDefinitionIcon resource={props.audioResource} />
                </td>

                <td className={"iconic"} width={10}>
                    <DeleteStopRemoveIconButton isMapped={props.isMapped} resource={props.audioResource} />
                </td>
            </tr>
            <tr className={"shift-hidden"}>
                <td colSpan={6}> localPath: { props.audioResource.localPath} </td>
            </tr>
            <tr className={"shift-hidden"}>
                <td colSpan={6}> uploadStatus: { props.audioResource.uploadStatus} </td>
            </tr>
            <tr className={"shift-hidden"}>
                <td colSpan={6}> asperaTransferId: { props.audioResource.asperaTransferId} </td>
            </tr>
            <tr className={"shift-hidden"}>
                <td colSpan={6}> s3path: {props.audioResource.s3Path} </td>
            </tr>
        </>
    );
};
