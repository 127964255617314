import {ITrack} from "../Projects/MapData";
import {useValidation} from "../validation/validation";
import {Resource} from "../api";
import { useStore } from "../State/zustandStore";
import { useProjects } from "../Behaviors/projects";
import {
    DolbyAssetIcon, HdAssetIcon, SdAssetIcon,
    ValidationBusyIcon,
    ValidationErrorIcon,
    ValidationPendingUploadIcon,
    //ValidationSuccessIcon,
    ValidationWarningIcon,
    ValidationInstructionIcon,
} from "./Icons";

interface TrackProps {
    Track: ITrack;
    HasMappedFile?: boolean
}

interface ResourceProps {
    resource: Resource;
}

export const AudioResourceDefinitionIcon = (props: ResourceProps) => {

    if (props.resource.audioInfo?.isDolby) {
        return <DolbyAssetIcon size={"large"} tooltip={"Dolby Atmos Audio"} />;
    }

    if ((props.resource.audioInfo?.bit_depth ?? 0) >= 24) {
        return <HdAssetIcon size={"large"} tooltip={"High Definition Audio"} />;
    }
    return <SdAssetIcon size={"large"} tooltip={"Standard Definition Audio"} />;
};

export const TrackStatus = (props: TrackProps) => {
    const track = props.Track;
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const {project} = useProjects(projectId);

    const {
        hasTrackValidationWarnings,
        hasTrackValidationErrors,
        isValidationInProgress,
        hasTrackPendingUpload,
        hasTrackValidationInstructions,
    } = useValidation();

    if (hasTrackValidationErrors(projectId, props.Track)) {
        return <ValidationErrorIcon />;
    }

    if (hasTrackValidationWarnings(projectId, props.Track)) {
        return <ValidationWarningIcon />;
    }

    if (hasTrackValidationInstructions(projectId, track, props.HasMappedFile || false)
        || (props.HasMappedFile !== null && props.HasMappedFile === false)) {
        return <ValidationInstructionIcon />;
    }

    if (isValidationInProgress(project?.jobId)) {
        return <ValidationBusyIcon />;
    }

    if (hasTrackPendingUpload(props.Track)) {
        return <ValidationPendingUploadIcon />;
    }
    //return <ValidationSuccessIcon />;
    return null; // AT-3614 do not display ValidationSuccessIcon if all is well
};
