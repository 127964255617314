import {Col, Row} from "react-bootstrap";
import EditIcon from "@mui/icons-material/Edit";
import {useEffect} from "react";
import {useCreateProject} from "../Behaviors/projects";
import {useAsperaApi} from "../aspera/aspera";
import { useWorkspace } from "../Behaviors/workspace";
import {Folder} from "./Folder";

export const WorkspaceView = () => {
    const {watchForAsperaEvents, stopWatchingForAsperaEvents} = useAsperaApi();

    // Subscribe to Aspera events
    useEffect(() => {
        watchForAsperaEvents();
        return stopWatchingForAsperaEvents;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        drafts,
        pending,
        inProgress,
        needsAttention,
        completed,
    } = useWorkspace();

    const {createProject} = useCreateProject();
    return <>
        <Row className=" h-100">
            <div id={"workspaceContainer"} >
                <Col className=" h-100    ">
                    <Row className="mt-3">
                        <Col>
                            <h3> Ingestion Jobs</h3>
                        </Col>
                    </Row>
                    <Row className="h-100  ">
                        <table className={"table  workspace  h-100 mt-2 mb-3  "}>
                            <tbody>
                                <tr onClick={async () => {
                                    await createProject();
                                }}>
                                    <td><EditIcon> </EditIcon></td>
                                    <td className="project">New Draft</td>
                                    <td />
                                    <td />
                                </tr>
                                <Folder name={"Drafts"} projects={drafts} />
                                <Folder name={"Uploading"} projects={pending} />
                                <Folder name={"In Progress"} projects={inProgress} />
                                <Folder name={"Needs Attention"} projects={needsAttention} />
                                <Folder name={"Completed"} projects={completed} />
                                <tr className={"filler-row"}>
                                    <td />
                                </tr>
                            </tbody>
                        </table>
                    </Row>
                </Col>
            </div>
        </Row>
    </>;
};
