import { Table } from "react-bootstrap";
import { FormatDuration, FormatTitle } from "../Formatting";
import { TrackStatus } from "../StatusIcons/TrackStatusIcon";
import { useProjects } from "../Behaviors/projects";
import { Resource, UploadStatus } from "../api";
import { useStore } from "../State/zustandStore";
import { ResourceMode, getResourceListMode } from "../Behaviors/projectTools";
import { ValidationPendingUploadIcon, ValidationSuccessIcon } from "../StatusIcons/Icons";
import { ValidationMessageTypeIcon } from "../StatusIcons/ValidationMessageTypeIcon";
import { useValidation } from "../validation/validation";
import { TrackBody } from "./TrackBody";
import { ITrack } from "./MapData";
import { AudioResource } from "./Views/Drafts/UPC_Tracks/AudioResource";
import { AsperaStatusIcon } from "./Actions/AsperaStatusButtonIcon";

interface Column<D> {
    accessor: keyof D,
    Header: string,
    minWidth?: string;
    rowSpan?: number
    colSpan?: number
    class?: string
    Formatter?: Function
}

export interface Props {
    allowEdits?: boolean;
}

export const IngestList = (props: Props) => {
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const { getTracks, getEffectiveResources, getMappedResource, project } = useProjects(projectId!);
    const resources = getEffectiveResources();
    const mode = getResourceListMode(resources);
    const jobId = project?.jobId;

    const { getValidationErrors, getValidationRestrictions, getValidationWarnings, getPendingUploadMessages } = useValidation();
    const messages =
        getPendingUploadMessages(jobId)
            .concat(getValidationErrors(jobId))
            .concat(getValidationRestrictions(jobId))
            .concat(getValidationWarnings(jobId));

    // eslint-disable-next-line no-console
    //console.log(`ProjectId ${projectId} JobId ${jobId} has ${messages.length} validation messages`);

    const IngestListTrackAsset = () => {
        const columns: Column<ITrack>[] = [
            {
                accessor: "number",
                Header: "track",
                class: "iconic",
            },
            {
                accessor: "title",
                Header: "Title",
                class: "word-break full-width",
                colSpan: 4,
                Formatter: FormatTitle,
            },
            {
                accessor: "duration",
                Header: "Duration",
                class: "numeric",
                Formatter: FormatDuration,
            },
            {
                accessor: "isrc",
                Header: "ISRC",
                class: "numeric",
                colSpan: 3,
            },
        ];
        return (
            <>
                <h3>Product Track List</h3>
                <Table role={"list"}
                    aria-labelledby="tracklist-heading"
                    className={"product-track-list mb-0  "}
                    aria-label="R2 Track List"
                    size="sm">
                    {
                        getTracks().map(item => {
                            const iAudioResource = getMappedResource(item) as Resource;
                            const msgType = messages.filter(x => x.source?.scope === "Track" && x.source?.target === item.number.toString())?.at(0)?.messageType;
                            return iAudioResource ?
                                (
                                    <TrackBody key={item.number} track={item}>
                                        <tr key={`${item.number}a`}>
                                            <td rowSpan={1} className={"iconic"}>
                                                {
                                                    msgType
                                                        ? <ValidationMessageTypeIcon messageType={msgType} />
                                                        : <TrackStatus Track={item} />
                                                }
                                            </td>
                                            {columns.map(col => {
                                                return (
                                                    <td key={col.accessor} rowSpan={col.rowSpan} colSpan={col.colSpan}
                                                        className={col.class}
                                                        style={{ minWidth: col.minWidth }}>
                                                        {col.Formatter ? col.Formatter(item[col.accessor], item.versionTitle) : item[col.accessor]}
                                                    </td>
                                                );
                                            },
                                            )}
                                            <td />
                                        </tr>
                                        <AudioResource
                                            projectId={projectId!}
                                            isOverlay={false}
                                            isMapped={true}
                                            key={iAudioResource.id}
                                            audioResource={iAudioResource} />
                                    </TrackBody>
                                ) : null;
                        })
                    }
                </Table>
            </>
        );
    };

    const statusIcon = (uploadStatus: UploadStatus | undefined) => {
        if (uploadStatus === UploadStatus.Complete) {
            return <ValidationSuccessIcon />;
        }
        return <ValidationPendingUploadIcon />;
    };
    const IngestListFile = () => {
        return (
            <>
                <h3 aria-label="resources-heading">File List</h3>
                <Table role={"list"}
                    className={"product-track-list mb-0  "}
                    aria-label="File List"
                    size="sm">
                    <tbody>
                        {
                            getEffectiveResources().map((item, index) => {
                                return (
                                    <tr key={`${index}a`}>
                                        <td rowSpan={1} className={"status-iconic"}>
                                            {statusIcon(item.uploadStatus)}
                                        </td>
                                        <td className={"word-break full-width"} >
                                            {item.filename}
                                        </td>
                                        <td className={"iconic"} >
                                            <AsperaStatusIcon projectId={projectId} resource={item} />
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>
            </>
        );
    };

    if (mode & ResourceMode.File) {
        return IngestListFile();
    } else {
        return IngestListTrackAsset();
    }
};
