import {Connect} from "@ibm-aspera/connect-sdk-js";
import {TransferInfo} from "@ibm-aspera/connect-sdk-js/src/core/types";
import {
    ConnectError,
    ConnectSpec,
    StartTransferOutput,
    TransferSpec,
} from "@ibm-aspera/connect-sdk-js/dist/esm/core/types";
import { useRef } from "react";
import {Project, Resource, UploadStatus} from "../api";
import {GetAsperaApi} from "../api/apiConfig";
import { useProjects } from "../Behaviors/projects";
import { getMappedResources } from "../Behaviors/projectTools";
import { useStore } from "../State/zustandStore";

const connectClient = new Connect();

const nonRecoverableErrorCodes = [11];

connectClient.initSession("IngestionTool");

const parseCookie = (cookie: string) => {
    const parts = cookie.split("|");

    if (parts.length === 2) {
        return {
            projectId: parts[0],
            resourceId: parts[1],
        };
    }

    console.error(`Invalid transfer cookie ${cookie}`);
    return {
        projectId: "",
        resourceId: "",
    };
};

async function getTransferForProject(projectId: string) : Promise<TransferInfo[]> {
    return new Promise((resolve, reject) => {
        connectClient.getAllTransfers({
            success: (transfers) => {
                const transfersForProject = transfers.transfers.filter(t => parseCookie(t.transfer_spec.cookie ?? "").projectId === projectId);

                resolve(transfersForProject);
            },
            error: () => resolve([]),
        });
    });
}

export const useAsperaApi = () => {
    const {updateUploadStatus, startIngest, revalidateProject} = useProjects(undefined);
    const {projects, aspera} = useStore();
    const {setUploadProgress, clearUploadProgress} = aspera;
    const projectsRef = useRef(projects);

    projectsRef.current = projects;

    async function createTransferRequest(projectId: string, resourceId: string, resourcePath: string) {
        async function transferStarted(output: StartTransferOutput) {
            for (const spec of output.transfer_specs) {
                spec.transfer_spec.paths.forEach(async p => {
                    await updateUploadStatus(projectId, resourceId, UploadStatus.InProgress, 0, "");
                });
            }
        }

        const api = await GetAsperaApi();
        const response = await api.asperaUploadSpecGet(resourcePath, projectId!);
        const transferSpec = response.data;

        //  @ts-expect-error
        transferSpec.allow_dialogs = false;
        return new Promise((resolve, reject) => {
            const t = transferSpec as TransferSpec;

            t.cookie = `${projectId}|${resourceId}`;
            connectClient.startTransfer(t, transferSpec as ConnectSpec, {
                success: (transfer) => {
                    resolve(transferStarted(transfer));
                },
                error: async function (error: ConnectError) {
                    resolve("failed");
                    await updateUploadStatus(projectId, resourceId, UploadStatus.Failed, 0, "");
                },
            });
        });
    }

    async function handleTransferEvents(type: any, data: { transfers: TransferInfo[]; }) {

        for (const transfer of data.transfers) {
            const {projectId, resourceId} = parseCookie(transfer.transfer_spec.cookie ?? "");

            if (!projectId) {
                console.error(`Aspera transfer with no project id ${transfer.uuid}`);
                return;
            }

            try {

                if (transfer.status === "running") {
                    setUploadProgress(projectId, resourceId, transfer.percentage);
                } else if (transfer.status === "cancelled") {
                    await updateUploadStatus(projectId, resourceId, UploadStatus.Failed, transfer.percentage, "");
                    projectsRef.current.logProjectEvent(projectId!, `Cancelled transfer ${transfer.uuid} for resource ${resourceId}`);
                } else if (transfer.status === "completed") {
                    //@ts-expect-error
                    const updatedProject = await updateUploadStatus(projectId, resourceId, UploadStatus.Complete, 1, transfer.transfer_spec.s3Path);

                    connectClient.removeTransfer(transfer.uuid);

                    projectsRef.current.logProjectEvent(projectId!, `Completed transfer ${transfer.uuid} for resource ${resourceId}`);

                    // the api determines when an ingestion can start based on the upload status of
                    // the effective resource.  If the startIngest flag is set, start the ingestion.
                    if (updatedProject.startIngestion) {
                        const selectedCprsShipments = projectsRef.current.selectedCprsShipments.get(updatedProject.projectId!);

                        await startIngest(updatedProject, "start ingest flag", selectedCprsShipments);
                        clearUploadProgress(projectId);
                    } else { // Otherwise check if all transfers for this project have completed and validate if true
                        const projectTransfers = await getTransferForProject(projectId);

                        // TODO: this can trigger multiple times if files complete very close to each other
                        if (projectTransfers.every(t => (t.status === "completed") || (t.status === "removed" && t.previous_status === "completed"))) {
                            await revalidateProject("uploads completed", false, updatedProject);
                        }
                    }
                } else if (transfer.status === "failed") {
                    //@ts-expect-error
                    const updatedProject = await updateUploadStatus(projectId, resourceId, UploadStatus.Failed, transfer.percentage, "", transfer.error_desc, transfer.error_code);

                    await connectClient.showTransferManager();
                    //@ts-expect-error
                    projectsRef.current.logProjectEvent(updatedProject.projectId!, `Failed transfer ${transfer.uuid} for resource ${resourceId} error ${transfer.error_desc}`);

                    //@ts-expect-error
                    if (nonRecoverableErrorCodes.includes(transfer.error_code)) {
                        await connectClient.removeTransfer(transfer.uuid);
                        await createTransferRequest(projectId, resourceId, transfer.current_file);
                    }
                } else if (transfer.status === "removed") {
                    if (transfer.previous_status !== "completed") {
                        await updateUploadStatus(projectId, resourceId, UploadStatus.Failed, transfer.percentage, "");
                        projectsRef.current.logProjectEvent(projectId, `Removed transfer ${transfer.uuid} for with status ${transfer.previous_status}`);
                    }
                } else {
                    if (transfer.status !== "queued" && transfer.status !== "initiating") {
                        projectsRef.current.logProjectEvent(projectId, `Unhandled aspera event for ${transfer.uuid} with status ${transfer.status}`);
                    }
                }
            } catch (error) {
                //@ts-expect-error
                projectsRef.current.logProjectEvent(projectId, `Aspera error ${transfer.uuid} status ${transfer.status} failed with the error:${error?.message}`);
            }
        }
    }

    function GetTransferEventForwarder() : (type: any, data: { transfers: TransferInfo[] }) => void {
        return handleTransferEvents;
    }

    function watchForAsperaEvents() {
        const forwarder = GetTransferEventForwarder();

        connectClient.addEventListener(Connect.EVENT.TRANSFER, forwarder);
    }

    function stopWatchingForAsperaEvents() {
        connectClient?.removeEventListener(Connect.EVENT.TRANSFER);
    }

    // TODO: make this do something.  Previous dispatch was not handled
    async function cancelUpload(resource: Resource) {
        /*
        if (resource.asperaTransferId) {
            await connectClient.removeTransfer(resource.asperaTransferId)
                .then(
                    () =>
                        dispatch({
                            name: "aspera.canceled",
                            projectId: state.selectedProjectId!,
                            resources: [resource],
                        }),
                )
                .catch(
                    () =>
                        dispatch({
                            name: "aspera.canceled",
                            projectId: state.selectedProjectId!,
                            resources: [resource],
                        }),
                );
        }
        */
    }

    async function getTransferForResource(resource: Resource) : Promise<TransferInfo | undefined> {
        return new Promise((resolve, reject) => {

            connectClient.getAllTransfers({
                success: (transfers) => {
                    transfers.transfers.forEach(t => {
                        const {resourceId} = parseCookie(t.transfer_spec.cookie ?? "");

                        if (resourceId === resource.id) {
                            resolve(t);
                        }
                    });
                    resolve(undefined);
                },
                error: () => resolve(undefined),
            });
        });
    }

    async function refreshResourceUploadStatus(resources: Resource[]) {
        resources.forEach(async resource => {
            const transfer = await getTransferForResource(resource);

            if (transfer) {
                await handleTransferEvents(null, { transfers: [transfer]});
            }
        });
    }

    async function uploadResource(resource: Resource, projectId: string) {
        // Don't place a new upload if the resource is already upload, or
        // has a transfer that isn't failed or cancelled
        if (resource.uploadStatus === UploadStatus.Complete) {
            projectsRef.current.logProjectEvent(projectId, `Duplicate upload request for ${resource.uploadStatus} resource ${resource.id}`);
            return;
        }

        const existingTransfer = await getTransferForResource(resource);

        if (existingTransfer) {
            if (existingTransfer.status === "failed" || existingTransfer.status === "cancelled") {
                connectClient.removeTransfer(existingTransfer.uuid);
                projectsRef.current.logProjectEvent(projectId, `Removed transfer ${existingTransfer.uuid} with status ${existingTransfer.status}`);
            } else {
                projectsRef.current.logProjectEvent(projectId, `Duplicate upload request for ${existingTransfer.status} transfer ${resource.filename}`);
                return;
            }
        }

        await createTransferRequest(projectId, resource.id!, resource.localPath!);
    }

    async function uploadResources(resources: Resource[], projectId: string) {
        for (const resource of resources) {
            await uploadResource(resource, projectId);
        }
    }

    async function uploadMapped(project: Project) {
        const mapped = getMappedResources(project);
        const resourcesToUpload = mapped.filter(resource => !resource.uploadStatus || resource.uploadStatus === UploadStatus.Unknown);

        if (resourcesToUpload.length > 0) {
            for (const resource of resourcesToUpload) {
                await uploadResource(resource, project.projectId!);
            }
        }
    }

    async function uploadAll(project: Project) {
        const resources = project.resources!;
        const resourcesToUpload = resources.filter(resource => !resource.uploadStatus || resource.uploadStatus === UploadStatus.Unknown);

        if (resourcesToUpload.length > 0) {
            for (const resource of resourcesToUpload) {
                await uploadResource(resource, project.projectId!);
            }
        }
    }
    return {
        watchForAsperaEvents,
        cancelUpload,
        uploadResource,
        uploadAll,
        stopWatchingForAsperaEvents,
        refreshResourceUploadStatus,
        uploadResources,
        uploadMapped,
    };
};
