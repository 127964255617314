import {useState} from "react";
import {Col, Row} from "react-bootstrap";
import { useStore } from "../State/zustandStore";
import {useProjects} from "../Behaviors/projects";
import {ProductSummary} from "./ProductSummary";
import {UpcPicker} from "./UpcPicker";
import {PopupUpcPicker} from "./PopupUpcPicker";

export const ProductInfo = () => {
    const state = useStore().projects;
    const projectId = state.selectedProjectId;
    const {project, setProjectUpc} = useProjects(projectId!);
    const upcLoaded = project?.releaseInfo != null;
    const summaryVisible = upcLoaded;
    const pickerVisible = !upcLoaded;
    const [loading, setLoading] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    function upcPicked(upcText: string) {
        setLoading(true);
        setProjectUpc(upcText).then(
            response => {
                setErrorMessage("");
                setPopupVisible(false);
            },
        ).catch(x => {
            setErrorMessage(x.response?.data?.message ?? x.message);
        })
            .finally(() => {
                setLoading(false);
            });

    }
    return (
        <Row>
            <Col>
                <UpcPicker errorMessage={errorMessage} loading={loading} onUpcPicked={upcPicked}
                    visible={pickerVisible} />
                <PopupUpcPicker errorMessage={errorMessage} loading={loading} onUpcPicked={upcPicked}
                    onHide={() => setPopupVisible(false)} visible={popupVisible} />
                <ProductSummary allowEdit={true} visible={summaryVisible} onEditClick={() => setPopupVisible(true)} />
            </Col>
        </Row>
    );
};
