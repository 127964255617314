import {Project, Resource, Track, TrackResource} from "../api";

interface Mapper {
    matcher: {
        getMatchedResources: (matchedTrack: Track, resources: Resource[]) => Resource[];
        getMatchedTracks: (tracks: Track[], resource: Resource) => Track[]
    };
}

const DurationMatcher = () => {
    const tolerance = 1;

    function getMatchedTracks(tracks: Track[], resource: Resource) {
        return tracks.filter(track => Math.abs(track.duration - (resource.audioInfo?.duration ?? 0)) < tolerance);
    }

    function getMatchedResources(matchedTrack: Track, resources: Resource[]) {
        const duration = matchedTrack?.duration ?? (tolerance * -2);
        return resources.filter(resource => Math.abs((resource.audioInfo?.duration ?? 0) - duration) < tolerance);
    }
    return {getMatchedTracks, getMatchedResources};
};

const TrackNumberMatcher = () => {
    function getTrackNumber(resource: Resource) {
        const filename = resource.filename ?? "";
        let numPart = "";

        for (let i = 0; i < filename.length; i++) {
            if (filename[i] >= "0" && filename[i] <= "9") {
                numPart += filename[i];
            } else {
                if (numPart.length > 0 && numPart.length <= 3)
                    return parseInt(numPart);
                else
                    numPart = "";
            }
        }

        if (numPart.length > 0 && numPart.length <= 3)
            return parseInt(numPart);
        return -1;
    }

    function getMatchedTracks(tracks: Track[], resource: Resource) {

        return tracks.filter(track => track.number === getTrackNumber(resource));
    }

    function getMatchedResources(track: Track, resources: Resource[]) {
        return resources.filter(resource => getTrackNumber(resource) === track.number);
    }
    return {getMatchedTracks, getMatchedResources};
};
const IsrcMatcher = () => {
    function getMatchedTracks(tracks: Track[], resource: Resource) {
        return tracks.filter(track => resource.filename?.toLowerCase()?.indexOf(track.isrc.toLowerCase()) !== -1);
    }

    function getMatchedResources(track: Track, resources: Resource[]) {
        return resources.filter(resource => resource.filename?.toLowerCase()?.indexOf(track.isrc.toLowerCase()) !== -1);
    }
    return {getMatchedTracks, getMatchedResources};
};

function isResourceMapped(resource: Resource, trackResourceMap: TrackResource[]) {
    if (trackResourceMap?.find(map => map.resourceId === resource.id))
        return true;
}

function isTrackMapped(track: Track, trackResourceMap: TrackResource[]) {
    if (trackResourceMap?.find(map => map.trackId === track.number.toString()))
        return true;
}

const Map = async (mapper: Mapper, resources: Resource[], tracks: Track[], trackResourceMap: TrackResource[]) => {
    const mappedResources = [] as Resource[];
    const resourceTracks : TrackResource[] = [];

    if (resources.length > 0) {
        for (const resource of resources) {
            const matchedTracks = mapper.matcher.getMatchedTracks(tracks, resource);

            if (matchedTracks.length === 1) {
                const matchedTrack = matchedTracks[0];
                const matchedResources = mapper.matcher.getMatchedResources(matchedTrack, resources);

                if (matchedResources.length === 1) {
                    const matchedResource = matchedResources[0];

                    if (!isResourceMapped(matchedResource, trackResourceMap ?? []) && !(isTrackMapped(matchedTrack, trackResourceMap ?? []))) {
                        resourceTracks.push({ resourceId: matchedResource.id!, trackId: matchedTrack.number.toString() });
                        mappedResources.push(matchedResource);
                    }
                }
            }
        }
    }
    return resourceTracks;
};

export const autoMap = async (project: Project) => {
    const resources = project.resources ?? [];
    let trackResourceMap = project.trackResourceMap ?? [];
    const tracks = project.releaseInfo?.tracks ?? [];

    trackResourceMap = await Map({ matcher: TrackNumberMatcher() }, resources, tracks, trackResourceMap);
    trackResourceMap = trackResourceMap.concat(await Map({ matcher: IsrcMatcher() }, resources, tracks, trackResourceMap));
    trackResourceMap = trackResourceMap.concat(await Map({ matcher: DurationMatcher() }, resources, tracks, trackResourceMap));
    return trackResourceMap;
};
