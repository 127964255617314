import {IngestionMessage} from "../asset-service-api";
import {useProjects} from "../Behaviors/projects";
import { useApp } from "../Behaviors/app";
import { useStore } from "../State/zustandStore";

export const useSdValidator = () => {
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const {project, getMappedTrack, getMappedResources} = useProjects(projectId!);
    const app = useApp();

    const isProductSd = () => {
        switch (project?.releaseInfo?.configuration) {
        case "Mastertone/Realtone/Ringback":
        case "Mastertone/Realtone (Speechtone + Mastertone)":
        case "eAlbum Audio":
        case "eAlbum Audio + Booklet":
        case "eFixedPlaylist Audio":
        case "eSingle Audio/Multi Track":
        case "eSingle Audio/Multi Track + Booklet":
        case "eSingle Audio/Single Track":
        case "eSingle Audio/Single Track + Booklet":
        case "eSnippet Audio":
        case "eMix Bundle Album":
        case "eMix Bundle Album + Booklet":
        case "eMix Bundle Album + LP":
        case "eMix Bundle Single":
        case "eMix Bundle Single + Booklet":
            return true;
        default:
            return false;
        }
    };
    const getSdBitDepthErrors = (): IngestionMessage[] => {
        const {enableHdForSd} = app.features;
        const allowedBitDepths = enableHdForSd ? [16, 24, 32] : [16];

        if (isProductSd()) {
            const errorResources = getMappedResources().filter(x => !allowedBitDepths.includes( x.audioInfo?.bit_depth ?? 0 ));
            return errorResources.map(x => {
                return {
                    details: `Asset ${ x.filename }, assigned to Track ${ getMappedTrack(x)?.number }, Bit Depth (${ x.audioInfo?.bit_depth }) must be (${enableHdForSd ? "16, 24, or 32" : "16"}) for configuration ${project?.releaseInfo?.configuration}`,
                    message: `Tracks on SD products must be ${enableHdForSd ? "16, 24, or 32" : "16"} bit.`,
                    messageType: "Error",
                    source: {
                        id: "6C6E5CAE-638D-406C-9FDB-CD665BC6AE4C",
                        scope: "Track",
                        stage: "local",
                        target: getMappedTrack(x)?.number.toString(),
                    },
                } as IngestionMessage;
            });
        } else return [];
    };
    return {getSdBitDepthErrors, isProductSd};
};
