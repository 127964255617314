import {IngestionMessage} from "../asset-service-api";
import {useProjects} from "../Behaviors/projects";
import { useStore } from "../State/zustandStore";

export const useHdValidator = () => {
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const {project, getMappedResources, getMappedTrack} = useProjects(projectId!);

    const isProductHd = () => {
        const releaseInfo = project?.releaseInfo;

        switch (releaseInfo?.configuration) {
        case "eAlbum Audio + Booklet HD":
        case "eAlbum Audio HD":
        case "eAudio Stem Bundle HD":
        case "eFixedPlaylist Audio HD":
        case "eSingle Audio/Multi Track + Booklet HD":
        case "eSingle Audio/Multi Track HD":
        case "eSingle Audio/Single Track + Booklet HD":
        case "eSingle Audio/Single Track HD":
        case "eAlbum Audio (Oscil8) HD":
        case "eSingle Audio/Single Track (Oscil8) HD":
        case "eSingle Audio/Multi Track (Oscil8) HD":
        case "eMix Bundle Album HD":
        case "eMix Bundle Album + Booklet HD":
        case "eMix Bundle Album + LP HD":
        case "eMix Bundle Single HD":
        case "eMix Bundle Single + Booklet HD":
            return true;
        default:
            return false;
        }
    };

    const isExplicit = () => {
        const releaseInfo = project?.releaseInfo;

        switch (releaseInfo?.parentalAdvisory) {
        case "Non-Applicable":
            return false;
        default:
            return true;
        }
    };
    const getHdBitDepthErrors = (): IngestionMessage[] => {
        const releaseInfo = project?.releaseInfo;

        if (isProductHd()) {
            const errorResources = getMappedResources().filter(x => (x.audioInfo?.bit_depth ?? 0 ) < 24);
            return errorResources.map(x => {
                return {
                    details: `Asset ${ x.filename }, assigned to Track ${ getMappedTrack(x)?.number }, Bit Depth (${ x.audioInfo?.bit_depth }) is less than the allowed minimum (24) for configuration ${ releaseInfo!.configuration }.`,
                    message: "Tracks on HD products must be at least 24 bit.",
                    messageType: "Error",
                    source: {
                        scope: "Track",
                        stage: "local",
                        target: getMappedTrack(x)?.number.toString(),
                        id: "7e7f7c03-5c8b-46bb-b62f-6b237a9d0f25",
                    },
                } as IngestionMessage;
            });
        } else return [];
    };
    return {getHdBitDepthErrors, isExplicit, isProductHd};

};
