/* tslint:disable */
/* eslint-disable */
/**
 * Asset Service
 * Asset Service 9fe60
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface Asset
 */
export interface Asset {
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'applicationUrl': string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'aspenId': string;
    /**
     * 
     * @type {AssetSuperType}
     * @memberof Asset
     */
    'assetSuperType': AssetSuperType;
    /**
     * 
     * @type {AssetType}
     * @memberof Asset
     */
    'assetType': AssetType;
    /**
     * 
     * @type {AudioMode}
     * @memberof Asset
     */
    'audioMode'?: AudioMode;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    'watermarkContentId'?: string | null;
    /**
     * 
     * @type {Array<FunctionalAssetIdentifier>}
     * @memberof Asset
     */
    'functionalAssetIdentifiers'?: Array<FunctionalAssetIdentifier> | null;
    /**
     * 
     * @type {Array<MediaFile>}
     * @memberof Asset
     */
    'mediaFiles': Array<MediaFile>;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AssetSuperType = {
    Audio: 'audio',
    Video: 'video',
    Graphic: 'graphic',
    Document: 'document'
} as const;

export type AssetSuperType = typeof AssetSuperType[keyof typeof AssetSuperType];


/**
 * 
 * @export
 * @enum {string}
 */

export const AssetType = {
    FullLength: 'fullLength',
    Trailer: 'trailer',
    MobileEdit: 'mobileEdit',
    CoverArt: 'coverArt',
    ScreenCapture: 'screenCapture',
    PublicityPhoto: 'publicityPhoto',
    DigitalBooklet: 'digitalBooklet',
    PartyImage: 'partyImage',
    Wallpaper: 'wallpaper',
    VideoCover: 'videoCover',
    Index: 'index',
    AudioMasterUpc: 'audioMasterUPC',
    AudioMasterIsrc: 'audioMasterISRC'
} as const;

export type AssetType = typeof AssetType[keyof typeof AssetType];


/**
 * 
 * @export
 * @enum {string}
 */

export const AudioMode = {
    Mono: 'mono',
    Stereo: 'stereo',
    SurroundSound51: 'surroundSound5_1',
    SurroundSound71: 'surroundSound7_1',
    DolbyAtmos: 'dolbyAtmos'
} as const;

export type AudioMode = typeof AudioMode[keyof typeof AudioMode];


/**
 * An ingestion request
 * @export
 * @interface Command
 */
export interface Command {
    /**
     * 
     * @type {CommandActionEnum}
     * @memberof Command
     */
    'action'?: CommandActionEnum;
    /**
     * 
     * @type {string}
     * @memberof Command
     */
    'upc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Command
     */
    'notes'?: string | null;
    /**
     * 
     * @type {Array<Resource>}
     * @memberof Command
     */
    'resources'?: Array<Resource> | null;
    /**
     * 
     * @type {Notifications}
     * @memberof Command
     */
    'notifications'?: Notifications;
    /**
     * 
     * @type {Array<string>}
     * @memberof Command
     */
    'overrideRestrictions'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Command
     */
    'correlationId'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof Command
     */
    'shippingDestinations'?: Array<number> | null;
    /**
     * 
     * @type {Array<CprsDestination>}
     * @memberof Command
     */
    'cprsDestinations'?: Array<CprsDestination> | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CommandActionEnum = {
    Unknown: 'Unknown',
    Ingest: 'Ingest',
    Validate: 'Validate',
    ValidateUpc: 'ValidateUpc'
} as const;

export type CommandActionEnum = typeof CommandActionEnum[keyof typeof CommandActionEnum];


/**
 * 
 * @export
 * @interface CprsDestination
 */
export interface CprsDestination {
    /**
     * 
     * @type {string}
     * @memberof CprsDestination
     */
    'upc'?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CprsDestination
     */
    'ids'?: Array<number> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ExecutionStateEnum = {
    Unknown: 'Unknown',
    Created: 'Created',
    Validating: 'Validating',
    Validated: 'Validated',
    ValidationFailed: 'ValidationFailed',
    CreatingBundle: 'CreatingBundle',
    Bundled: 'Bundled',
    Processing: 'Processing',
    Completed: 'Completed',
    Failed: 'Failed',
    Exception: 'Exception',
    Suspended: 'Suspended'
} as const;

export type ExecutionStateEnum = typeof ExecutionStateEnum[keyof typeof ExecutionStateEnum];


/**
 * 
 * @export
 * @enum {string}
 */

export const Format = {
    Wav: 'wav',
    Flac: 'flac',
    Mp3: 'mp3',
    Mp4: 'mp4',
    Mpeg4: 'mpeg4',
    Mpegps: 'mpegps',
    Mpg: 'mpg',
    Mxf: 'mxf',
    Jpg: 'jpg',
    Jpeg: 'jpeg',
    Tiff: 'tiff',
    Mov: 'mov',
    Pdf: 'pdf',
    Xls: 'xls',
    Doc: 'doc',
    Txt: 'txt',
    Quicktime: 'quicktime',
    Avi: 'AVI',
    Png: 'PNG',
    Dat: 'DAT',
    Dsf: 'DSF',
    Cmf: 'CMF'
} as const;

export type Format = typeof Format[keyof typeof Format];


/**
 * 
 * @export
 * @interface FunctionalAssetIdentifier
 */
export interface FunctionalAssetIdentifier {
    /**
     * 
     * @type {FunctionalIdentifierType}
     * @memberof FunctionalAssetIdentifier
     */
    'identifierType': FunctionalIdentifierType;
    /**
     * 
     * @type {string}
     * @memberof FunctionalAssetIdentifier
     */
    'assetIdentifier'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FunctionalAssetIdentifier
     */
    'assetSubIdentifier'?: string | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const FunctionalIdentifierType = {
    Isrc: 'isrc',
    Upc: 'upc',
    UpcTrack: 'upcTrack',
    PartyId: 'partyId',
    ArtistName: 'artistName',
    ProjectId: 'projectId',
    SessionId: 'sessionId',
    VspId: 'vspId',
    CatalogNumber: 'catalogNumber',
    CatalogNumberTrack: 'catalogNumberTrack',
    R2ReleaseId: 'r2ReleaseId',
    R2ReleaseIdTrack: 'r2ReleaseIdTrack'
} as const;

export type FunctionalIdentifierType = typeof FunctionalIdentifierType[keyof typeof FunctionalIdentifierType];


/**
 * 
 * @export
 * @interface IngestionMessage
 */
export interface IngestionMessage {
    /**
     * The date and time the message was created.
     * @type {string}
     * @memberof IngestionMessage
     */
    'timeStamp'?: string;
    /**
     * The user facing message.
     * @type {string}
     * @memberof IngestionMessage
     */
    'message'?: string | null;
    /**
     * 
     * @type {ValidationMessageType}
     * @memberof IngestionMessage
     */
    'messageType'?: ValidationMessageType;
    /**
     * A more detailed message, available for some rules.
     * @type {string}
     * @memberof IngestionMessage
     */
    'details'?: string | null;
    /**
     * 
     * @type {MessageSource}
     * @memberof IngestionMessage
     */
    'source'?: MessageSource;
}


/**
 * 
 * @export
 * @interface Job
 */
export interface Job {
    /**
     * The unique identifier of the ingestion job.
     * @type {string}
     * @memberof Job
     */
    'id'?: string;
    /**
     * 
     * @type {ExecutionStateEnum}
     * @memberof Job
     */
    'state'?: ExecutionStateEnum;
    /**
     * 
     * @type {Command}
     * @memberof Job
     */
    'command'?: Command;
    /**
     * 
     * @type {number}
     * @memberof Job
     */
    'ingestionId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'jobUri'?: string | null;
    /**
     * 
     * @type {Array<IngestionMessage>}
     * @memberof Job
     */
    'messages'?: Array<IngestionMessage> | null;
    /**
     * 
     * @type {number}
     * @memberof Job
     */
    'orderProcessorID'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'productUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'ownerId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'ownerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'callerOid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'callerName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Job
     */
    'versionNumber'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Job
     */
    'onersOverridePermissions'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Job
     */
    'activeOverrides'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'autoQcSubmission'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    'autoQcCompletion'?: string | null;
}


/**
 * 
 * @export
 * @interface Manufacturer
 */
export interface Manufacturer {
    /**
     * 
     * @type {number}
     * @memberof Manufacturer
     */
    'manufacturerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Manufacturer
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Manufacturer
     */
    'asperaDestinationId'?: number;
    /**
     * 
     * @type {string}
     * @memberof Manufacturer
     */
    'asperaDestinationName'?: string | null;
}
/**
 * 
 * @export
 * @interface MediaFile
 */
export interface MediaFile {
    /**
     * 
     * @type {MediaFileType}
     * @memberof MediaFile
     */
    'mediaFileType': MediaFileType;
    /**
     * 
     * @type {MediaFileSubType}
     * @memberof MediaFile
     */
    'mediaFileSubType': MediaFileSubType;
    /**
     * 
     * @type {Format}
     * @memberof MediaFile
     */
    'format': Format;
    /**
     * 
     * @type {number}
     * @memberof MediaFile
     */
    'bitDepth'?: number;
    /**
     * 
     * @type {number}
     * @memberof MediaFile
     */
    'samplingFrequency'?: number;
    /**
     * 
     * @type {number}
     * @memberof MediaFile
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof MediaFile
     */
    'height'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaFile
     */
    'colorMode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MediaFile
     */
    'colorDepth'?: number;
    /**
     * 
     * @type {number}
     * @memberof MediaFile
     */
    'dpi'?: number;
    /**
     * 
     * @type {string}
     * @memberof MediaFile
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof MediaFile
     */
    'origFileName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MediaFile
     */
    'fileSize': number;
    /**
     * 
     * @type {string}
     * @memberof MediaFile
     */
    'md5Checksum': string;
    /**
     * 
     * @type {string}
     * @memberof MediaFile
     */
    'uri': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const MediaFileSubType = {
    Full: 'full',
    Clip: 'clip',
    Small: 'small',
    Medium: 'medium',
    Large: 'large'
} as const;

export type MediaFileSubType = typeof MediaFileSubType[keyof typeof MediaFileSubType];


/**
 * 
 * @export
 * @enum {string}
 */

export const MediaFileType = {
    Master: 'master',
    Preview: 'preview'
} as const;

export type MediaFileType = typeof MediaFileType[keyof typeof MediaFileType];


/**
 * 
 * @export
 * @enum {string}
 */

export const MessageScope = {
    Unknown: 'Unknown',
    Product: 'Product',
    Track: 'Track',
    System: 'System',
    Request: 'Request',
    Email: 'Email',
    Uri: 'URI'
} as const;

export type MessageScope = typeof MessageScope[keyof typeof MessageScope];


/**
 * Describes the source of an ingestion job message.
 * @export
 * @interface MessageSource
 */
export interface MessageSource {
    /**
     * For validation rules, the rule ID.
     * @type {string}
     * @memberof MessageSource
     */
    'id'?: string | null;
    /**
     * 
     * @type {MessageScope}
     * @memberof MessageSource
     */
    'scope'?: MessageScope;
    /**
     * The Upc, Track or other item the message is about.
     * @type {string}
     * @memberof MessageSource
     */
    'target'?: string | null;
    /**
     * The stage in the job that created the message. E.g. Validation or Processing
     * @type {string}
     * @memberof MessageSource
     */
    'stage'?: string | null;
    /**
     * The name of step, or validation rule the created the message.
     * @type {string}
     * @memberof MessageSource
     */
    'name'?: string | null;
    /**
     * The type of action that created the message, e.g. \"Rule\" or \"Step\"
     * @type {string}
     * @memberof MessageSource
     */
    'type'?: string | null;
}


/**
 * Wraps the email list in the request
 * @export
 * @interface Notifications
 */
export interface Notifications {
    /**
     * 
     * @type {Array<string>}
     * @memberof Notifications
     */
    'email'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface Permissions
 */
export interface Permissions {
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    'canIngest'?: boolean;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'detail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    'instance'?: string | null;
}
/**
 * 
 * @export
 * @interface Release
 */
export interface Release {
    /**
     * 
     * @type {string}
     * @memberof Release
     */
    'upc'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Release
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Release
     */
    'artist'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Release
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Release
     */
    'version'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Release
     */
    'packageUpc'?: string | null;
    /**
     * 
     * @type {Array<Manufacturer>}
     * @memberof Release
     */
    'manufacturers'?: Array<Manufacturer> | null;
}
/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * The uri of the resource to ingest. Must be accessible to ingestion service API.  Presigned URLS are recommended.
     * @type {string}
     * @memberof Resource
     */
    'uri'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    'isrc'?: string | null;
    /**
     * The MD5 Checksum of the resource.
     * @type {string}
     * @memberof Resource
     */
    'md5'?: string | null;
    /**
     * The Track Number for this resource.
     * @type {number}
     * @memberof Resource
     */
    'trackNumber'?: number;
    /**
     * The Track Group for this resource.
     * @type {number}
     * @memberof Resource
     */
    'trackGroup'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ValidationMessageType = {
    Unknown: 'Unknown',
    Information: 'Information',
    Warning: 'Warning',
    Error: 'Error',
    Exception: 'Exception',
    Skipped: 'Skipped',
    Restricted: 'Restricted',
    RestrictedResolved: 'RestrictedResolved',
    RestrictedResolvable: 'RestrictedResolvable',
    Instruction: 'Instruction'
} as const;

export type ValidationMessageType = typeof ValidationMessageType[keyof typeof ValidationMessageType];

/**
 * 
 * @export
 * @interface ValidationRuleInfo
 */
export interface ValidationRuleInfo {
    /**
     * 
     * @type {string}
     * @memberof ValidationRuleInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ValidationRuleInfo
     */
    'id'?: string | null;
    /**
     * 
     * @type {MessageScope}
     * @memberof ValidationRuleInfo
     */
    'scope'?: MessageScope;
    /**
     * 
     * @type {string}
     * @memberof ValidationRuleInfo
     */
    'targetType'?: string | null;
}



/**
 * AssetRetrievalApi - axios parameter creator
 * @export
 */
export const AssetRetrievalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves an asset by its AspenId.
         * @param {string} aspenId The Asset Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsAspenIdGet: async (aspenId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aspenId' is not null or undefined
            assertParamExists('assetsAspenIdGet', 'aspenId', aspenId)
            const localVarPath = `/assets/{aspenId}`
                .replace(`{${"aspenId"}}`, encodeURIComponent(String(aspenId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetRetrievalApi - functional programming interface
 * @export
 */
export const AssetRetrievalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetRetrievalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves an asset by its AspenId.
         * @param {string} aspenId The Asset Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assetsAspenIdGet(aspenId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Asset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assetsAspenIdGet(aspenId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetRetrievalApi.assetsAspenIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssetRetrievalApi - factory interface
 * @export
 */
export const AssetRetrievalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetRetrievalApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves an asset by its AspenId.
         * @param {string} aspenId The Asset Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assetsAspenIdGet(aspenId: string, options?: AxiosRequestConfig): AxiosPromise<Asset> {
            return localVarFp.assetsAspenIdGet(aspenId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetRetrievalApi - object-oriented interface
 * @export
 * @class AssetRetrievalApi
 * @extends {BaseAPI}
 */
export class AssetRetrievalApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves an asset by its AspenId.
     * @param {string} aspenId The Asset Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetRetrievalApi
     */
    public assetsAspenIdGet(aspenId: string, options?: AxiosRequestConfig) {
        return AssetRetrievalApiFp(this.configuration).assetsAspenIdGet(aspenId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IngestionApi - axios parameter creator
 * @export
 */
export const IngestionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the status of a job.
         * @param {string} jobId The ID of the job to retrieve.
         * @param {MessageScope} [messageSourceScope] Show only messages with a given source scope.
         * @param {string} [messageSourceType] Show only messages with a given source type. e.g. Rule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ingestionJobsJobIdGet: async (jobId: string, messageSourceScope?: MessageScope, messageSourceType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobId' is not null or undefined
            assertParamExists('ingestionJobsJobIdGet', 'jobId', jobId)
            const localVarPath = `/ingestion/jobs/{jobId}`
                .replace(`{${"jobId"}}`, encodeURIComponent(String(jobId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (messageSourceScope !== undefined) {
                localVarQueryParameter['messageSourceScope'] = messageSourceScope;
            }

            if (messageSourceType !== undefined) {
                localVarQueryParameter['messageSourceType'] = messageSourceType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *  Command:          Action:           required.           permitted values: \"validate\" | \"ingest\"       upc:           optional.         resources:           List of resources to ingest.       Notifications:           optional.     Email:     required.           List of emails to send the result to. (max of 10 emails)   OverrideRestrictions:    optional.    supported values [\"*\"] to override all restrictions.     Resource:          uri:           required.           permitted values: Valid accessible urls including presigned urls.           reserved (future) values: S3 locations, other.       isrc:           optional (required for all currently supported use cases.)           permitted values: ISRC values available in R2.       md5:           optional.           permitted values: an md5 checksum.       trackNumber:           optional.           permitted values: track sequence number.       trackGroup:          optional.    permitted values: track group number.                          Supported use cases:         ISRC-WAV: Ingest a single wav with the specified ISRC.         Must not have a UPC.           Must have exactly 1 resource.           Must have an ISRC on each resource.            TRACK-ASSETS: Ingest a list of tracks for the specified UPC.             Must have a UPC.           Must have 1 or more resources.           Must have an ISRC on each resource.           Must have a track number on each resource.
         * @summary Creates an ingestion job.
         * @param {MessageScope} [messageSourceScope] Show only messages with a given source scope.
         * @param {string} [messageSourceType] Show only messages with a given source type. e.g. Rule
         * @param {Command} [command] The ingestion command.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ingestionJobsPost: async (messageSourceScope?: MessageScope, messageSourceType?: string, command?: Command, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ingestion/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (messageSourceScope !== undefined) {
                localVarQueryParameter['messageSourceScope'] = messageSourceScope;
            }

            if (messageSourceType !== undefined) {
                localVarQueryParameter['messageSourceType'] = messageSourceType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(command, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the user permissions for a specific ISRC
         * @param {string} isrc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ingestionPermissionsIsrcsIsrcGet: async (isrc: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'isrc' is not null or undefined
            assertParamExists('ingestionPermissionsIsrcsIsrcGet', 'isrc', isrc)
            const localVarPath = `/ingestion/permissions/isrcs/{isrc}`
                .replace(`{${"isrc"}}`, encodeURIComponent(String(isrc)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the user permissions for a specific UPC
         * @param {string} upc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ingestionPermissionsUpcsUpcGet: async (upc: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upc' is not null or undefined
            assertParamExists('ingestionPermissionsUpcsUpcGet', 'upc', upc)
            const localVarPath = `/ingestion/permissions/upcs/{upc}`
                .replace(`{${"upc"}}`, encodeURIComponent(String(upc)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IngestionApi - functional programming interface
 * @export
 */
export const IngestionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IngestionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the status of a job.
         * @param {string} jobId The ID of the job to retrieve.
         * @param {MessageScope} [messageSourceScope] Show only messages with a given source scope.
         * @param {string} [messageSourceType] Show only messages with a given source type. e.g. Rule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ingestionJobsJobIdGet(jobId: string, messageSourceScope?: MessageScope, messageSourceType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Job>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ingestionJobsJobIdGet(jobId, messageSourceScope, messageSourceType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IngestionApi.ingestionJobsJobIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         *  Command:          Action:           required.           permitted values: \"validate\" | \"ingest\"       upc:           optional.         resources:           List of resources to ingest.       Notifications:           optional.     Email:     required.           List of emails to send the result to. (max of 10 emails)   OverrideRestrictions:    optional.    supported values [\"*\"] to override all restrictions.     Resource:          uri:           required.           permitted values: Valid accessible urls including presigned urls.           reserved (future) values: S3 locations, other.       isrc:           optional (required for all currently supported use cases.)           permitted values: ISRC values available in R2.       md5:           optional.           permitted values: an md5 checksum.       trackNumber:           optional.           permitted values: track sequence number.       trackGroup:          optional.    permitted values: track group number.                          Supported use cases:         ISRC-WAV: Ingest a single wav with the specified ISRC.         Must not have a UPC.           Must have exactly 1 resource.           Must have an ISRC on each resource.            TRACK-ASSETS: Ingest a list of tracks for the specified UPC.             Must have a UPC.           Must have 1 or more resources.           Must have an ISRC on each resource.           Must have a track number on each resource.
         * @summary Creates an ingestion job.
         * @param {MessageScope} [messageSourceScope] Show only messages with a given source scope.
         * @param {string} [messageSourceType] Show only messages with a given source type. e.g. Rule
         * @param {Command} [command] The ingestion command.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ingestionJobsPost(messageSourceScope?: MessageScope, messageSourceType?: string, command?: Command, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Job>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ingestionJobsPost(messageSourceScope, messageSourceType, command, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IngestionApi.ingestionJobsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the user permissions for a specific ISRC
         * @param {string} isrc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ingestionPermissionsIsrcsIsrcGet(isrc: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Permissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ingestionPermissionsIsrcsIsrcGet(isrc, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IngestionApi.ingestionPermissionsIsrcsIsrcGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get the user permissions for a specific UPC
         * @param {string} upc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ingestionPermissionsUpcsUpcGet(upc: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Permissions>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ingestionPermissionsUpcsUpcGet(upc, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IngestionApi.ingestionPermissionsUpcsUpcGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * IngestionApi - factory interface
 * @export
 */
export const IngestionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IngestionApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the status of a job.
         * @param {string} jobId The ID of the job to retrieve.
         * @param {MessageScope} [messageSourceScope] Show only messages with a given source scope.
         * @param {string} [messageSourceType] Show only messages with a given source type. e.g. Rule
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ingestionJobsJobIdGet(jobId: string, messageSourceScope?: MessageScope, messageSourceType?: string, options?: AxiosRequestConfig): AxiosPromise<Job> {
            return localVarFp.ingestionJobsJobIdGet(jobId, messageSourceScope, messageSourceType, options).then((request) => request(axios, basePath));
        },
        /**
         *  Command:          Action:           required.           permitted values: \"validate\" | \"ingest\"       upc:           optional.         resources:           List of resources to ingest.       Notifications:           optional.     Email:     required.           List of emails to send the result to. (max of 10 emails)   OverrideRestrictions:    optional.    supported values [\"*\"] to override all restrictions.     Resource:          uri:           required.           permitted values: Valid accessible urls including presigned urls.           reserved (future) values: S3 locations, other.       isrc:           optional (required for all currently supported use cases.)           permitted values: ISRC values available in R2.       md5:           optional.           permitted values: an md5 checksum.       trackNumber:           optional.           permitted values: track sequence number.       trackGroup:          optional.    permitted values: track group number.                          Supported use cases:         ISRC-WAV: Ingest a single wav with the specified ISRC.         Must not have a UPC.           Must have exactly 1 resource.           Must have an ISRC on each resource.            TRACK-ASSETS: Ingest a list of tracks for the specified UPC.             Must have a UPC.           Must have 1 or more resources.           Must have an ISRC on each resource.           Must have a track number on each resource.
         * @summary Creates an ingestion job.
         * @param {MessageScope} [messageSourceScope] Show only messages with a given source scope.
         * @param {string} [messageSourceType] Show only messages with a given source type. e.g. Rule
         * @param {Command} [command] The ingestion command.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ingestionJobsPost(messageSourceScope?: MessageScope, messageSourceType?: string, command?: Command, options?: AxiosRequestConfig): AxiosPromise<Job> {
            return localVarFp.ingestionJobsPost(messageSourceScope, messageSourceType, command, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the user permissions for a specific ISRC
         * @param {string} isrc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ingestionPermissionsIsrcsIsrcGet(isrc: string, options?: AxiosRequestConfig): AxiosPromise<Permissions> {
            return localVarFp.ingestionPermissionsIsrcsIsrcGet(isrc, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the user permissions for a specific UPC
         * @param {string} upc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ingestionPermissionsUpcsUpcGet(upc: string, options?: AxiosRequestConfig): AxiosPromise<Permissions> {
            return localVarFp.ingestionPermissionsUpcsUpcGet(upc, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IngestionApi - object-oriented interface
 * @export
 * @class IngestionApi
 * @extends {BaseAPI}
 */
export class IngestionApi extends BaseAPI {
    /**
     * 
     * @summary Gets the status of a job.
     * @param {string} jobId The ID of the job to retrieve.
     * @param {MessageScope} [messageSourceScope] Show only messages with a given source scope.
     * @param {string} [messageSourceType] Show only messages with a given source type. e.g. Rule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public ingestionJobsJobIdGet(jobId: string, messageSourceScope?: MessageScope, messageSourceType?: string, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).ingestionJobsJobIdGet(jobId, messageSourceScope, messageSourceType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *  Command:          Action:           required.           permitted values: \"validate\" | \"ingest\"       upc:           optional.         resources:           List of resources to ingest.       Notifications:           optional.     Email:     required.           List of emails to send the result to. (max of 10 emails)   OverrideRestrictions:    optional.    supported values [\"*\"] to override all restrictions.     Resource:          uri:           required.           permitted values: Valid accessible urls including presigned urls.           reserved (future) values: S3 locations, other.       isrc:           optional (required for all currently supported use cases.)           permitted values: ISRC values available in R2.       md5:           optional.           permitted values: an md5 checksum.       trackNumber:           optional.           permitted values: track sequence number.       trackGroup:          optional.    permitted values: track group number.                          Supported use cases:         ISRC-WAV: Ingest a single wav with the specified ISRC.         Must not have a UPC.           Must have exactly 1 resource.           Must have an ISRC on each resource.            TRACK-ASSETS: Ingest a list of tracks for the specified UPC.             Must have a UPC.           Must have 1 or more resources.           Must have an ISRC on each resource.           Must have a track number on each resource.
     * @summary Creates an ingestion job.
     * @param {MessageScope} [messageSourceScope] Show only messages with a given source scope.
     * @param {string} [messageSourceType] Show only messages with a given source type. e.g. Rule
     * @param {Command} [command] The ingestion command.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public ingestionJobsPost(messageSourceScope?: MessageScope, messageSourceType?: string, command?: Command, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).ingestionJobsPost(messageSourceScope, messageSourceType, command, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the user permissions for a specific ISRC
     * @param {string} isrc 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public ingestionPermissionsIsrcsIsrcGet(isrc: string, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).ingestionPermissionsIsrcsIsrcGet(isrc, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the user permissions for a specific UPC
     * @param {string} upc 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestionApi
     */
    public ingestionPermissionsUpcsUpcGet(upc: string, options?: AxiosRequestConfig) {
        return IngestionApiFp(this.configuration).ingestionPermissionsUpcsUpcGet(upc, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ManufacturingApi - axios parameter creator
 * @export
 */
export const ManufacturingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get manufacturers of a UPC - including all packaged UPCs configured in CPRS   [AND have shipping destination configured in ASPEN].
         * @param {string} upc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersUpcGet: async (upc: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'upc' is not null or undefined
            assertParamExists('manufacturersUpcGet', 'upc', upc)
            const localVarPath = `/manufacturers/{upc}`
                .replace(`{${"upc"}}`, encodeURIComponent(String(upc)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManufacturingApi - functional programming interface
 * @export
 */
export const ManufacturingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManufacturingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get manufacturers of a UPC - including all packaged UPCs configured in CPRS   [AND have shipping destination configured in ASPEN].
         * @param {string} upc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manufacturersUpcGet(upc: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Release>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manufacturersUpcGet(upc, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManufacturingApi.manufacturersUpcGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ManufacturingApi - factory interface
 * @export
 */
export const ManufacturingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManufacturingApiFp(configuration)
    return {
        /**
         * 
         * @summary Get manufacturers of a UPC - including all packaged UPCs configured in CPRS   [AND have shipping destination configured in ASPEN].
         * @param {string} upc 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manufacturersUpcGet(upc: string, options?: AxiosRequestConfig): AxiosPromise<Array<Release>> {
            return localVarFp.manufacturersUpcGet(upc, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManufacturingApi - object-oriented interface
 * @export
 * @class ManufacturingApi
 * @extends {BaseAPI}
 */
export class ManufacturingApi extends BaseAPI {
    /**
     * 
     * @summary Get manufacturers of a UPC - including all packaged UPCs configured in CPRS   [AND have shipping destination configured in ASPEN].
     * @param {string} upc 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManufacturingApi
     */
    public manufacturersUpcGet(upc: string, options?: AxiosRequestConfig) {
        return ManufacturingApiFp(this.configuration).manufacturersUpcGet(upc, options).then((request) => request(this.axios, this.basePath));
    }
}



