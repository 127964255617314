/* eslint-disable no-console */
/* eslint-disable indent */
import { Table } from "react-bootstrap";
import { FormatDuration, FormatTitle } from "../Formatting";
import { TrackStatus } from "../StatusIcons/TrackStatusIcon";
import { useValidation } from "../validation/validation";
import { UnMapAllButton } from "../Buttons/Buttons";
import { useProjects } from "../Behaviors/projects";
import { Resource } from "../api";
import { useStore } from "../State/zustandStore";
import { TrackBody } from "./TrackBody";
import { DraggableAudioResource } from "./Views/Drafts/UPC_Tracks/DraggableAudioResource";
import { ITrack } from "./MapData";

interface Column<D> {
    accessor: keyof D,
    Header: string,
    minWidth?: string;
    rowSpan?: number
    colSpan?: number
    class?: string
    Formatter?: Function
}

export const TrackList = () => {

    const {
        hasTrackValidationErrors,
        hasTrackValidationWarnings,
        //hasTrackValidationInstructions,
        getTrackValidationErrors,
        getTrackValidationWarnings,
        getTrackValidationInstructions,
    } = useValidation();
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    //const { project, getMappedResource, getTracks, getTrackGroups } = useProjects(projectId!);
    const { project, getMappedResource, getTrackGroups } = useProjects(projectId!);
    const jobId = project?.jobId;
    //const tracks2 = getTracks();
    const trackGroups = getTrackGroups();
    const hasMultipleTrackGoups = trackGroups.length > 1;

    //console.log("TrackList", trackGroups.length, tracks2.length, hasMultipleTrackGoups);

    const columns: Column<ITrack>[] = [
        {
            accessor: "number",
            Header: "track",
            class: "iconic",
        },
        {
            accessor: "title",
            Header: "Title",
            class: "word-break full-width",
            colSpan: 4,
            Formatter: FormatTitle,
        },
        {
            accessor: "duration",
            Header: "Duration",
            class: "numeric",
            Formatter: FormatDuration,
        },
        {
            accessor: "isrc",
            Header: "ISRC",
            class: "numeric",
            colSpan: 3,
        },
    ];
    return (
        <>
            <h3>Product Track List</h3>
            <Table role={"list"}
                aria-labelledby="tracklist-heading"
                className={"product-track-list mb-0  "}
                aria-label="R2 Track List"
                size="sm">
                {
                    trackGroups.map(g => {
                        return (
                            <>
                                {
                                    hasMultipleTrackGoups ?
                                        <tbody aria-label={`Track Group ${g.groupNumber}g`}>
                                            <tr>
                                                <td colSpan={6} >
                                                    Track Group {g.groupNumber}
                                                </td>
                                                <td className='numeric'>
                                                    {FormatDuration(g.duration)}
                                                </td>
                                                <td />
                                            </tr>
                                        </tbody> : <></>
                                }
                                {
                                    g.tracks.map(track => {
                                        const iAudioResource = getMappedResource(track) as Resource;
                                        const hasMappedFile = iAudioResource !== undefined;
                                        return (
                                            <TrackBody key={track.number} track={track}>
                                                <tr key={`${track.number}a`}>
                                                    <td rowSpan={3} className={"status-iconic"}>
                                                        <TrackStatus Track={track} HasMappedFile={hasMappedFile} />
                                                    </td>
                                                    {columns.map(col => {
                                                        return (
                                                            <td key={col.accessor} rowSpan={col.rowSpan} colSpan={col.colSpan}
                                                                className={col.class}
                                                                style={{ minWidth: col.minWidth }}>
                                                                {col.Formatter ? col.Formatter(track[col.accessor], track.versionTitle) : track[col.accessor]}
                                                            </td>
                                                        );
                                                    },
                                                    )}
                                                    <td />
                                                </tr>
                                                {iAudioResource ?
                                                    <DraggableAudioResource
                                                        projectId={projectId!}
                                                        isOverlay={false}
                                                        isMapped={true}
                                                        // className={(isDurationEqual(track, iAudioResource)) ? 'alert-success' : 'alert-warning'}
                                                        key={iAudioResource.id}
                                                        audioResource={iAudioResource} />
                                                    : null
                                                }
                                                {hasTrackValidationWarnings(jobId, track) || hasTrackValidationErrors(jobId, track)
                                                    ? <tr key={`${track.number}warn`}>
                                                        <td colSpan={9}>
                                                            {getTrackValidationErrors(jobId, track).concat(getTrackValidationWarnings(jobId, track))[0].message}
                                                        </td>
                                                    </tr>
                                                    : null
                                                }
                                                {/*hasTrackValidationInstructions(jobId, track, hasMappedFile) &&*/
                                                 !hasMappedFile && (!hasTrackValidationWarnings(jobId, track) && !hasTrackValidationErrors(jobId, track))
                                                    ? <tr key={`${track.number}info`}>
                                                        <td colSpan={9} className={"track-validation-instruction"}>
                                                            {getTrackValidationInstructions(jobId, track, hasMappedFile)[0]?.message
                                                                || "Drop a file from the left to map it to this track"}
                                                        </td>
                                                    </tr>
                                                    : null
                                                }
                                            </TrackBody>
                                        );
                                    })
                                }
                            </>
                        );
                    })
                }
                <tbody className={"filler-row"}>
                    <tr className={"filler-row"} />
                </tbody>
                <tfoot>
                    <tr>
                        <td style={{ width: "100%" }} colSpan={9} />
                        <td className={"iconic"}><UnMapAllButton /></td>
                    </tr>
                </tfoot>
            </Table>
        </>
    );
};
