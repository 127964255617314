import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import {
    Project, Resource, ResourceType, Track, TrackGroup,
    TrackResource, UploadStatus } from "../api";
import { ITrack } from "../Projects/MapData";
import { trackMapConfig } from "../Projects/Views/Drafts/UPC_Tracks/trackMapConfig";
import {GetProjectsApi} from "../api/apiConfig";
import { useStore } from "../State/zustandStore";
import { CommandActionEnum, CprsDestination } from "../asset-service-api";
import { getValidationCommand, getIngestCommand, useMutateJobs, useIngestionJobs } from "./ingest.api";

interface FilesAddedEvent {
    resourcesAdded: number,
    resourcesReplaced: number,
    resourcesRemoved: number,
    tooManyResources: number,
}

function formatLogError(error: AxiosError, projectId: string, fnName: string) {
    const messages = [] as string[];

    //@ts-ignore
    if (error.response?.data?.errors) {
    //@ts-ignore
        var keys = Object.getOwnPropertyNames(error.response.data.errors);

        keys.forEach(element => {
        //@ts-ignore
            messages.push(`   ${element}: ${error.response.data.errors[element][0]}`);
        });
        return `${fnName} error:\n${messages.join("\n")}`;
    } else {
        return `${fnName} error: ${error.message}`;
    }

}

// Execute mutation, add or update result to projects cache, and set in project/id cache
const useMutateProject = () => {
    const queryClient = useQueryClient();
    const {logProjectEvent, clearProjectMessage, setProjectMessage} = useStore().projects;
    return useMutation({
        mutationFn: ({ fn, projectId, ignoreErrors } : { fn: (...args : any[]) => Promise<AxiosResponse<Project, any>>, projectId: string | undefined, ignoreErrors?: number[] | undefined}) => {
            return fn();
        },
        onMutate: (variables) => {
            setProjectMessage(variables.projectId!, "⌛");
        },
        onSuccess: (response, variables) => { // populate the cache with the new project
            if (response.status === 204) { // Updates might be discarded if the status isn't complete
                logProjectEvent(variables.projectId!, "Discarded resource update");
                return;
            }

            queryClient.setQueryData(["projects"], (old: Project[]) => {
                if (old.find(o => o.projectId === response.data.projectId)) {
                    return old.map(o => o.projectId === response.data.projectId ? response.data : o);
                }
                return old.concat([response.data]);
            });
            //queryClient.setQueryData(["project", response.data.projectId], response.data);
            queryClient.invalidateQueries({ queryKey: ["project", response.data.projectId]});
        },
        onError: (error, variables) => {
            if (variables.projectId) {
                logProjectEvent(variables.projectId, formatLogError(error as AxiosError, variables.projectId!, variables.fn.name));
            }

            // @ts-expect-error
            if (error.response?.status !== 409 && !variables.ignoreErrors?.includes(error.response?.status)) {
                // @ts-expect-error
                toast.error(`An error occurred updating the project. Status: ${error.response?.status}`);
            }
        },
        onSettled: (project, error, variables) => {
            if (project && project.data && project.data.projectId) {
                clearProjectMessage(project.data.projectId);
            } else if (variables?.projectId) {
                clearProjectMessage(variables.projectId);
            }
        },
    });
};

// Execute mutation, remove from projects cache.  stale project/id will be removed automatically
const useMutateProjectDelete = () => {
    const queryClient = useQueryClient();
    const {logProjectEvent} = useStore().projects;
    return useMutation({
        mutationFn: async ({ projectId } : { projectId: string }) => {
            const api = await GetProjectsApi();
            return await api._delete(projectId);
        },
        onSuccess: (response, variables) => { // remove the project from the cache
            queryClient.setQueryData(["projects"], (old: Project[]) => {
                return old.filter(o => o.projectId !== variables.projectId);
            });
        },
        onError: (error, variables) => {
            if (variables.projectId) {
                logProjectEvent(variables.projectId, formatLogError(error as AxiosError, variables.projectId!, "_delete"));
            }
        },
    });
};

const useProject = (projectId: string | undefined | null) => {
    const projectQuery = useQuery({
        queryKey: ["project", projectId ?? undefined],
        queryFn: async () => {
            const api = await GetProjectsApi();
            return (await api.getProject(projectId!)).data;
        },
        enabled: projectId !== null && projectId !== undefined,
    });
    return projectQuery.data;
};

export const useCreateProject = () => {
    const mutateProject = useMutateProject();
    const store = useStore().projects;

    async function createProject() {
        const api = await GetProjectsApi();
        const createProject = () => api.createProject();
        const response = await mutateProject.mutateAsync({ fn: createProject, projectId: "" });
        const project = response.data;

        store.logProjectEvent(project.projectId!, "Created project");
        store.setSelectedProjectId(project.projectId!);
    }
    return {
        createProject,
    };
};

export const useProjects = (selectedProjectId: string | null | undefined) => {
    const projectStore = useStore((state) => state.projects);
    const mutateProject = useMutateProject();
    const mutateProjectDelete = useMutateProjectDelete();
    const mutateJobs = useMutateJobs();
    const project = useProject(selectedProjectId);
    const queryClient = useQueryClient();
    const jobs = useIngestionJobs();

    async function validate(validateProject: Project, source: string, forceValidation: boolean) {
        if (!validateProject.releaseInfo) return;

        // Validating after placing an ingest order is only allowed when
        // clicking the send back to drafts button in needs attention view
        if (!forceValidation && validateProject.jobId) {
            const job = jobs.getJob(validateProject.jobId!);

            if (job.command?.action === CommandActionEnum.Ingest) {
                projectStore.logProjectEvent(validateProject.projectId!, `Prevented validate due to previous ingestion ${job.id}`);
                return;
            }
        }

        const command = await getValidationCommand(validateProject);

        const response = await mutateJobs.mutateAsync({ command, projectId: validateProject.projectId!, source });
        return response.data.id;
    }

    async function ingest(ingestProject: Project, source: string, selectedCprsShipments?: string[]) {
        const command = await getIngestCommand(ingestProject);

        if (selectedCprsShipments) {
            const shipments = [] as CprsDestination[];

            for (const selection of selectedCprsShipments!) {
                const upcManufactureId = selection.split("|");
                let cprs = shipments.find(s => s.upc === upcManufactureId[0]);

                if (!cprs) {
                    cprs = {upc: upcManufactureId[0], ids: []} as CprsDestination;
                    shipments.push(cprs);
                }

                cprs.ids!.push(Number(upcManufactureId[1]));
            }

            command.cprsDestinations = shipments;
        }

        const response = await mutateJobs.mutateAsync({ command, projectId: ingestProject.projectId!, source });
        return response.data.id;
    }

    async function executeMutation(apiFunction: () => Promise<AxiosResponse<Project, any>>, revalidate: boolean, projectId: string, ignoreErrors?: number[] | undefined) {
        const response = await mutateProject.mutateAsync({ fn: apiFunction, projectId: projectId, ignoreErrors: ignoreErrors });
        let updatedProject = response.data;

        if (revalidate && updatedProject && updatedProject.releaseInfo?.upc) {
            const validateResponse = await validate(updatedProject, "project update", false);

            if (validateResponse) {
                updatedProject.jobId = validateResponse;

                const api = await GetProjectsApi();
                const setJobId = () => api.setJobId(updatedProject.projectId!, validateResponse, new Date().toISOString());
                const response = await mutateProject.mutateAsync({ fn: setJobId, projectId: updatedProject.projectId! });

                updatedProject = response.data;
            }
        }
        return updatedProject;
    }
    async function saveProject(project: Project, revalidate: boolean) {
        const api = await GetProjectsApi();
        const updateProject = () => api.updateProject(project);
        const updatedProject = await executeMutation(updateProject, revalidate, project.projectId!);
        return updatedProject;
    }

    async function revalidateProject(source: string, forceRevalidation: boolean, passedProject?: Project) {
        const targetProject = passedProject ?? project;

        if (targetProject && targetProject.releaseInfo?.upc) {
            const validateResponse = await validate(targetProject, source, forceRevalidation);

            if (validateResponse) {
                targetProject.jobId = validateResponse;

                // forceRevalidation is only set when clicking 'send back to drafts'
                if (forceRevalidation) {
                    targetProject.isIngestRequestedAfterUpload = false;
                }

                await saveProject(targetProject, false);
            }
        }
    }

    async function deleteResources(resources: Resource[]) {
        if (project && project.resources) {
            const newResources = project.resources.filter(r => {
                return !resources.includes(r);
            });

            project.resources = newResources;
            await saveProject(project, true);
        }
    }

    async function setProjectNotes(notes: string) {
        if (project) {
            project.notes = notes;
            await saveProject(project, false); // project notes do not get revalidated automatically
        }
    }

    async function setProjectUpc(upc: string) {
        if (project) {
            const api = await GetProjectsApi();
            const setUpc = () => api.setProjectUpc(project.projectId!, upc);
            const newProject = await executeMutation(setUpc, true, project.projectId!, [404]);
            return newProject;
        }
    }

    async function setOverrideRestrictions(overrideRestrictions: boolean) {
        if (project) {
            const api = await GetProjectsApi();
            const setOverrideRestrictions = () => api.setProjectOverrideRestrictions(project?.projectId!, overrideRestrictions);
            return await executeMutation(setOverrideRestrictions, true, project.projectId!);
        }
    }

    async function setIsIngestRequestedAfterUpload(state: boolean, passedProject?: Project) {
        const targetProject = passedProject ?? project;

        if (targetProject) {
            const api = await GetProjectsApi();
            const setIsIngestRequestedAfterUpload = () => api.setIsIngestRequestedAfterUpload(targetProject.projectId!, state);
            const updatedProject = await executeMutation(setIsIngestRequestedAfterUpload, false, targetProject.projectId!);
            return updatedProject;
        }
    }

    async function duplicateProject(projectId: string) {
        const api = await GetProjectsApi();
        const duplicateProject = () => api.duplicateProject(projectId);

        const newProject = (await mutateProject.mutateAsync({ fn: duplicateProject, projectId })).data;

        projectStore.logProjectEvent(newProject.projectId!, `Created duplicate project from ${projectId}`);
        projectStore.setSelectedProjectId(newProject.projectId!);
    }

    async function deleteProject(project: Project) {
        if (project.projectId) {
            await mutateProjectDelete.mutateAsync({ projectId: project.projectId! });
        }

        if (projectStore.selectedProjectId === project.projectId) {
            projectStore.clearSelectedProjectId();
        }
    }

    // This is called from the DnD callback, which has a stale closure so
    // it uses the project id as a param instead of the selected project id
    async function addResources(projectId: string, resources: Resource[]) {
        const api = await GetProjectsApi();
        const addResources = () => api.addResources(projectId, resources);
        const updatedProject = await executeMutation(addResources, true, projectId!); // Do not revalidate when adding resources
        return updatedProject;
    }

    async function unmapResource(resourceId: string) {
        if (project) {
            project.trackResourceMap = project.trackResourceMap?.filter(t => t.resourceId !== resourceId);
            return await saveProject(project, true);
        }
    }

    async function mapResourceToTrack(resourceId: string, trackNo: number) {
        if (project) {
            const api = await GetProjectsApi();
            const mapTrack = () => api.mapResource(project?.projectId!, trackNo.toString(), resourceId);
            return await executeMutation(mapTrack, true, project.projectId!);
        }
    }

    async function mapResourcesToTrack(resourceTracks : TrackResource[]) {
        if (project) {
            const api = await GetProjectsApi();
            let updatedProject = project;

            for (const [i, resourceTrack] of resourceTracks.entries()) {
                const revalidate = i === resourceTracks.length - 1; // don't revalidate until last track is mapped
                const mapTrack = () => api.mapResource(project.projectId!, resourceTrack.trackId!, resourceTrack.resourceId!);

                updatedProject = await executeMutation(mapTrack, revalidate, project.projectId!);
            }
            return updatedProject;
        }
    }

    // Called from aspera handler, not necessarily tied to the selected project
    async function updateUploadStatus(projectId: string, resourceId: string, uploadStatus: UploadStatus, uploadPercentage: number, s3Path: string, errorDescription?: string, errorCode?: number) {
        const api = await GetProjectsApi();
        const updateResource = () => api.updateUploadState(projectId, resourceId, uploadStatus, uploadPercentage, s3Path, errorDescription, errorCode);
        const updatedProject = await executeMutation(updateResource, false, projectId!, [404]);
        return updatedProject;
    }

    const resetMap = async () => {
        try {
            if (project) {
                project.trackResourceMap = [];
                await saveProject(project, true);
            }
        } catch (error) {
            projectStore.logProjectEvent(project?.projectId!, `Reset map error ${error}`);
        }
    };

    function isResourceMapped(resource: Resource) {
        const resourceMap = project?.trackResourceMap;

        if (resourceMap) {
            return project?.trackResourceMap?.find(x => x.resourceId === resource.id) !== undefined;
        }
        return false;
    }

    function isTrackIdMapped(trackId: string) {
        return project?.trackResourceMap?.find(x => x.trackId === trackId) !== undefined;
    }

    function getSelectedProjectResources() {
        return project?.resources;
    }

    function getSelectedProjectReleaseInfo() {
        return project?.releaseInfo;
    }

    function getTracks() {
        const releaseInfo = getSelectedProjectReleaseInfo();
        return releaseInfo?.tracks ?? new Array<Track>();
    }

    function getTrackGroups() {
        const releaseInfo = getSelectedProjectReleaseInfo();
        return releaseInfo?.trackGroups ?? new Array<TrackGroup>();
    }

    function isOverrideChecked() {
        if (project) {

            return project.overrideRestrictions?.find(x => x === "*") !== undefined;
        }
        return false;
    }

    function getUnmappedResources(): Resource[] {
        const selectedProjectResources = getSelectedProjectResources();
        const x = selectedProjectResources!.filter(x => !isResourceMapped(x)).values();
        return Array.from(x);
    }

    function getMappedResources(passedProject?: Project): Resource[] {
        const targetProject = passedProject ?? project;
        const resources = targetProject?.resources ?? [];

        const x = resources!.filter(x => isResourceMapped(x)).values();
        return Array.from(x);
    }

    // Get mapped resource if track asset mode, otherwise all resources
    function getEffectiveResources(passedProject?: Project): Resource[] {
        const targetProject = passedProject ?? project;
        const resources = targetProject?.resources;

        if (resources?.every(r => r.resourceType === ResourceType.Audio)) {
            return getMappedResources(project);
        }
        return resources || [];
    }

    function getMappedResource(track: ITrack): Resource | undefined {
        const mapping = project?.trackResourceMap?.find(x => x.trackId === track.number.toString());
        return project?.resources?.find(x => x.id === mapping?.resourceId);
    }

    function getMappedTrack(resource: Resource): Track | undefined {

        const mappedTrackId = project?.trackResourceMap?.find(x => x.resourceId === resource.id)?.trackId;

        if (mappedTrackId) {
            const projectTracks = getSelectedProjectReleaseInfo()?.tracks;
            return projectTracks?.find(x => x.number.toString() === mappedTrackId);
        }
    }

    const getFilesAddedEvent = (resources: Resource[]): FilesAddedEvent => {
        const newStateChangeEvent: FilesAddedEvent = {
            resourcesAdded: 0,
            resourcesReplaced: 0,
            resourcesRemoved: 0,
            tooManyResources: 0,
        };
        const tempResources = [...project?.resources ?? []];

        for (const resource of resources) {
            if (tempResources.find(x => x.id === resource.id)) {
                newStateChangeEvent.resourcesReplaced++;
                tempResources.push(resource);
            } else {
                if (tempResources.length < trackMapConfig.maxResourcesInList) {
                    tempResources.push(resource);
                    newStateChangeEvent.resourcesAdded++;
                } else {
                    newStateChangeEvent.tooManyResources++;
                }
            }
        }
        return newStateChangeEvent;
    };

    // This is only used when the submit job button is clicked.  If all resources are ready at
    // that time, then ingest will start.
    async function ingestIfReady(): Promise<{ started: boolean, project: Project | undefined }> {
        if (!project) {
            return { started: false, project: undefined };
        }

        let updatedProject = await setIsIngestRequestedAfterUpload(true);
        const effectiveResources = getEffectiveResources(updatedProject);

        if (updatedProject && effectiveResources.length >= 0) {

            const isUploadComplete = effectiveResources!.every(x => x.uploadStatus === UploadStatus.Complete);

            if (isUploadComplete) {
                const selectedCprsShipments = projectStore.selectedCprsShipments.get(project.projectId!);
                const validateResponse = await ingest(project, "ingest button", selectedCprsShipments);

                if (validateResponse) {
                    updatedProject!.jobId = validateResponse;
                    updatedProject = await saveProject(updatedProject!, false);
                    return { started: true, project: updatedProject };
                }
            }
        }
        return { started: false, project: updatedProject };
    }

    // Called from aspera when all resources for a project are uploaded
    async function startIngest(updatedProject: Project, source: string, selectedCprsShipments?: string[]): Promise<Project> {
        // This flag isn't saved in the DB.  Disable is in the cache so that it
        // can't be retriggered on a refresh
        updatedProject.startIngestion = false;
        queryClient.setQueryData(["project", updatedProject.projectId!], updatedProject);

        const validateResponse = await ingest(updatedProject, source, selectedCprsShipments);

        if (validateResponse) {
            updatedProject.jobId = validateResponse;
            updatedProject = await saveProject(updatedProject!, false);
        }
        return updatedProject;
    }

    const isProductSelected = () => {
        return project?.upc ?? "";
    };
    return {
        duplicateProject,

        // crud
        deleteProject,

        // productInfo
        setProjectUpc,
        setProjectNotes,
        getSelectedProjectReleaseInfo,
        getTracks,
        getTrackGroups,
        isProductSelected,

        // resources
        getSelectedProjectResources,
        deleteResources,
        getUnmappedResources,
        getMappedResources,
        getEffectiveResources,
        getMappedResource,
        getFilesAddedEvent,
        addResources,

        // mapping
        isTrackIdMapped,
        getMappedTrack,
        resetMap,
        mapResourceToTrack,
        mapResourcesToTrack,
        unmapResource,

        revalidateProject,

        setIsIngestRequestedAfterUpload,
        setOverrideRestrictions,
        isOverrideChecked,
        ingestIfReady,
        startIngest,
        project,

        updateUploadStatus,
    };
};
