import {useMemo} from "react";
import {CloudDone, CloudOff, CloudQueue, Remove} from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import {Tooltip} from "@mui/material";
import { useStore } from "../../State/zustandStore";
import {useAsperaApi} from "../../aspera/aspera";
import {useProjects} from "../../Behaviors/projects";
import {Resource, UploadStatus} from "../../api";

export interface IAsperaError {
    code: number;
    description: string;
}

export interface IAsperaResource {
    asperaUploadPercentage?: number;
    asperaError?: IAsperaError;
    asperaTransferId?: string;
}

export interface Props {
    resource: Resource;
    isMapped?: boolean;
    projectId?: string;
}

const IsComplete = (props: Props) => props.resource.uploadStatus === UploadStatus.Complete;

const IsInProgress = (props: Props) => (props.resource.uploadStatus === UploadStatus.InProgress);

const IsErrorState = (props: Props) => props.resource.uploadStatus === UploadStatus.Failed;

const IsReady = (props: Props) => (!(IsComplete(props) || IsErrorState(props) || IsInProgress(props)));

const Progress = (props: Props) => {
    const visible = useMemo(() => IsInProgress(props), [props]);
    const {uploadProgress} = useStore().aspera;
    let percentage = uploadProgress.get(props.projectId!)?.get(props.resource.id!);

    if (props.resource.uploadStatus === UploadStatus.Complete) {
        percentage = 1;
    }
    return visible ?
        (
            <>
                {((percentage ?? 0) * 100).toFixed()}%
            </>
        )
        : null;
};

// TODO: implement this
/*
const CancelUploadButton = (props: Props) => {
    const dispatch = useDispatch();
    const {cancelUpload} = useAsperaApi();
    const visible = useMemo(() => IsInProgress(props), [props]);
    return visible ?
        (
            <>
                <Cancel className="icon-button  " onClick={() => cancelUpload(props.resource)} />

            </>
        )
        : null;
};
*/

// TODO: implement this
const Error = (props: Props) => {
    const visible = useMemo(() => IsErrorState(props), [props]);
    const {uploadResource} = useAsperaApi();
    return visible ?
        (
            <Tooltip onClick={() => uploadResource(props.resource, props.projectId!)}
                title={`${props.resource.uploadError?.description ?? "An unknown error occured uploading this file." }. Click the error icon to clear the error and retry.`}>
                <CloudOff className="icon-buttonn icon--state-error" />
            </Tooltip>
        )
        : null;
};

const Complete = (props: Props) => {
    const visible = useMemo(() => IsComplete(props), [props]);
    return visible ?
        (
            <Tooltip title={"Upload Complete"}>
                <CloudDone className="" />
            </Tooltip>
        )
        : null;
};

const Ready = (props: Props) => {
    const visible = useMemo(() => IsReady(props), [props]);
    const {uploadResource} = useAsperaApi();
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    return visible ?
        (

            <CloudQueue className="icon-button" onClick={() => uploadResource(props.resource, projectId!)} />
        )
        : null;
};

const NotStarted = (props: Props) => {
    const visible = useMemo(() => IsReady(props), [props]);
    return visible ?
        (

            <Tooltip title={"File is not uploaded"}><CloudQueueIcon /></Tooltip>
        )
        : null;
};

const Delete = (props: Props) => {
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const {deleteResources} = useProjects(projectId!);
    return (
        <Tooltip title="Remove this file from the project">
            <DeleteIcon className="icon-button" onMouseDown={(event) => event.preventDefault()}
                onClick={() => deleteResources([props.resource])} />
        </Tooltip>
    );
};

const UnMap = (props: Props) => {
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const {unmapResource} = useProjects(projectId!);
    return (
        <Tooltip title="Unmap this file from the track">
            <Remove className="icon-button"
                onMouseDown={(event) => event.preventDefault()}
                onClick={() => unmapResource(props.resource.id!)} />
        </Tooltip>
    );
};

export const DeleteStopRemoveIconButton = (props: Props) => {
    return (

        // IsInProgress(props) ?
        //     <CancelUploadButton {...props}  /> :
        props.isMapped ?
            <UnMap {...props} /> :
            <Delete {...props} />
    );

};

export const AsperaStatusButtonIcon = (props: Props) => {
    return (
        <>
            <Complete {...props} />
            <Progress {...props} />
            <Error {...props} />
            <Ready {...props} />
        </>
    );
};

export const AsperaStatusIcon = (props: Props) => {
    return (
        <>
            <Complete {...props} />
            <Progress {...props} />
            <Error {...props} />
            <NotStarted {...props} />
        </>
    );
};
