import {Row} from "react-bootstrap";
import {Step, StepLabel, Stepper} from "@mui/material";
import {useProjects} from "../Behaviors/projects";
import {Project} from "../api";
import { CommandActionEnum, ExecutionStateEnum } from "../asset-service-api";
import { useIngestionJobs } from "../Behaviors/ingest.api";
import { useStore } from "../State/zustandStore";

export const IngestProgressBar = () => {
    const jobs = useIngestionJobs();
    const getStep = (project: Project): number => {
        if (project?.jobId === null || undefined) {
            return 0;
        } else {
            const job = jobs.getJob(project.jobId!);
            const action = job?.command?.action;

            if (action === CommandActionEnum.Validate || action === null)
                return 0;

            const state = job?.state;

            switch (state) {
            case ExecutionStateEnum.Created:
            case ExecutionStateEnum.Validating:
            case ExecutionStateEnum.Validated:
                return 1;
            case ExecutionStateEnum.CreatingBundle:
            case ExecutionStateEnum.Bundled:
                return 2;
            case ExecutionStateEnum.Processing:
                return 3;
            case ExecutionStateEnum.Completed:
                return 5;
            default:
                return 0;
            }
        }
    };
    const workspaceState = useStore().projects;
    const projectId = workspaceState.selectedProjectId;
    const {project} = useProjects(projectId);
    return (<div>
        <Row className={"mt-3"}>
            <Stepper alternativeLabel activeStep={getStep(project!)}>
                <Step key="1">
                    <StepLabel>Upload</StepLabel>
                </Step>
                <Step key="2">
                    <StepLabel>Validate</StepLabel>
                </Step>
                <Step key="3">
                    <StepLabel>Create Bundle</StepLabel>
                </Step>
                <Step key="4">
                    <StepLabel>Process</StepLabel>
                </Step>
                <Step key="5">
                    <StepLabel>Complete</StepLabel>
                </Step>
            </Stepper>

        </Row>
    </div>);
};
