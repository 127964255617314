import {useQueries, useQuery, useQueryClient} from "@tanstack/react-query";
import { useEffect } from "react";
import {GetProjectsApi} from "../api/apiConfig";
import { CommandActionEnum, ExecutionStateEnum, Job, ValidationMessageType } from "../asset-service-api";
import { useStore } from "../State/zustandStore";
import { useAsperaApi } from "../aspera/aspera";
import { UploadStatus } from "../api";
import { useValidation } from "../validation/validation";
import { getJobQueryOptions } from "./ingest.api";
import { ProjectState, getMappedResources, getProjectState } from "./projectTools";

export const useWorkspace = () => {
    const store = useStore();
    const {uploadResources} = useAsperaApi();
    const {getValidationErrors, getValidationRestrictions} = useValidation();
    // Get the projects for the user
    const {data: projects = []} = useQuery({
        queryKey: ["projects"],
        queryFn: async () =>
        {
            const api = await GetProjectsApi();
            const response = await api.getProjects();

            if (!response.status) {
                throw new Error("Network response was not ok");
            }
            return response.data;
        },
    });

    // Fetch all of the jobs that appear on projects
    const queryClient = useQueryClient();
    const jobQueries = useQueries({
        queries: projects && Array.isArray(projects)
            ? projects.filter(p => p.jobId).map(p => {
                const existing = queryClient.getQueryData<Job>(["job", p.jobId]);
                return getJobQueryOptions(p.jobId!, existing?.state, existing?.command?.action);
            })
            : [], // if projectIds is undefined, an empty array will be returned
    });

    const jobs = jobQueries.map(j => j.data);

    const jobStates = jobs.map(job => {
        const state = job ? getProjectState(job) : 0 as ProjectState.Unknown;
        return { jobId: job?.id, state};
    });

    // This is to handle auto upload of mapped tracks if there are no errors or restrictions
    function projectsToUpload() {
        const projectResources = new Map();

        if (store.preferences.autoUploadMappedTracks) { // The user has enabled this feature
            const validatedJobs = jobs.filter(j => j?.command?.action === CommandActionEnum.Validate && (j.state === ExecutionStateEnum.Validated || j.state === ExecutionStateEnum.ValidationFailed)); // Validate jobs that are done

            for (const job of validatedJobs) {
                const project = projects.find(p => p.jobId === job?.id); // Job is currently attached to a project
                const errorsAndRestrictions = getValidationErrors(job?.id!).concat(getValidationRestrictions(job?.id!).filter(v => v.messageType === ValidationMessageType.Restricted));

                if (project && errorsAndRestrictions.length === 0) { // project has no errors or restrictions

                    const mappedResourcesNotUploaded = getMappedResources(project).filter(r => !r.uploadStatus || r.uploadStatus === UploadStatus.Unknown);
                    const md5s = job?.command?.resources?.map(r => r.md5);

                    const resourcesToUpload = mappedResourcesNotUploaded.filter(r => md5s?.includes(r.md5)); // only include files that where part of the validation job

                    if (resourcesToUpload.length > 0) {
                        projectResources.set(project.projectId!, resourcesToUpload);
                    }
                }
            }
        }
        return projectResources;
    }

    const uploads = projectsToUpload();
    // This is used to trigger the effect only when the projects needing uploads changes
    // This should make it safe to exclude the uploads map from dependencies.
    const trigger = JSON.stringify(Array.from(uploads.keys()).sort());

    useEffect(() => {
        for (const projectId of uploads.keys()) {
            uploadResources(uploads.get(projectId), projectId)
                .catch((err) => console.error(projectId, `Auto upload of mapped tracks error ${err.message}`));
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger]);

    const drafts = projects.filter(p => !p.jobId || (jobStates.find(j => j.jobId === p.jobId && j!.state === ProjectState.Draft && p.isIngestRequestedAfterUpload !== true)));
    const pending = projects.filter(p => jobStates.find(j => j.jobId === p.jobId && j.state === ProjectState.Draft && p.isIngestRequestedAfterUpload === true));
    const inProgress = projects.filter(p => jobStates.find(j => j.jobId === p.jobId && j.state === ProjectState.Processing));
    const needsAttention = projects.filter(p => jobStates.find(j => j.jobId === p.jobId && j.state === ProjectState.NeedsAttention));
    const completed = projects.filter(p => jobStates.find(j => j.jobId === p.jobId && j.state === ProjectState.Completed));
    return {
        projects,
        drafts,
        pending,
        inProgress,
        needsAttention,
        completed,
    };
};
